@import "../styles/variables.scss";

.footer {
	padding: 30px;
	padding-top: 40px;
	background-color: $bg;
	font-size: 1rem;
	font-weight: 300;
	font-family: $Roboto;
	&__icons {
		display: flex;
		flex-direction: row;
		justify-content: center;

		padding-top: 20px;
		padding-bottom: 50px;
	}
}

.icon {
	cursor: pointer;
	padding: 0px 20px;
}
