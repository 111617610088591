@import "../styles/variables.scss";

.header {
	position: fixed;
	z-index: 1;
	background: transparent;
	width: 100%;
	height: $headerHeight;
	padding: 0 1.5rem;
	@include breakpoint(md) {
		padding: 0 3rem;
	}
	transition: 0.3s ease all;

	&__content {
		overflow: hidden;
		color: $light;
		margin: 0 auto;
		max-width: 1920px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		&__logo {
			cursor: pointer;
			color: transparent;
			@include breakpoint(md) {
				font-size: 1.7rem;
				font-weight: 600;
				text-decoration: none;
				color: white;
			}
		}

		&__nav {
			top: 0;
			right: 100%;
			bottom: 0;
			width: 100%;
			height: 100%;
			position: fixed;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			background: rgba($dark, 0.9);
			backdrop-filter: blur(2px);
			transform: translate(0);
			transition: 0.3s ease transform;

			@include breakpoint(md) {
				backdrop-filter: blur(0px);
				transform: none;
				flex-direction: row;
				background: transparent;
				width: auto;
				height: 100%;
				position: static;
			}

			ul {
				list-style: none;
				padding: 0;

				text-decoration: none;
				display: flex;
				flex-direction: column;
				margin-bottom: $spacing-lg;
				@include breakpoint(md) {
					flex-direction: row;
					align-items: center;
					margin-bottom: 0;
					margin-right: calc(0.5rem+#{$spacing-md});
				}
				li {
					&:not(:last-child) {
						margin-bottom: $spacing-lg;
						@include breakpoint(md) {
							margin-bottom: 0;
							margin-right: $spacing-md;
						}
					}
					a {
						cursor: pointer;
						font-size: 1.2rem;
						text-decoration: none;
						color: white;
						padding: 0.375rem 2.625rem;
						margin: 0 20px;
						transition: 0.3s ease all;

						&:hover {
							border-bottom: 2px solid white;
							font-size: 1.4rem;
						}
					}
				}
			}
			button {
				cursor: pointer;

				width: 8.125rem;
				height: 2.5rem;
				margin: 0px 25px;
				font-size: 1rem;
				color: white;
				border-radius: 10px;
				background-image: linear-gradient(to right, #fc9867 0%, #ff6188 51%, #fc9867 100%);
				background-size: 200% auto;
				transition: all 0.3s ease;

				&:hover {
					transition: all 0.3s ease;
					background-position: right center; /* change the direction of the change here */
					color: white;
					text-decoration: none;
				}

				&:active {
					transition: all 0.3s ease;
					background-position: right center; /* change the direction of the change here */
					color: #fff;
					text-decoration: none;
				}
			}
			&.isMenu {
				transform: translate(100%);
			}
		}
		&__toggle {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 2rem;
			transition: 0.3s ease all;
			position: relative;
			color: $pink;

			&:hover {
				color: $orange;
			}

			@include breakpoint(md) {
				display: none;
			}
		}
	}
}
