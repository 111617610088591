@import "./variables.scss";

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	user-select: none;
	scroll-behavior: smooth;
}

body {
	width: 100%;
	font-family: $DMSans;
	background-color: $dark;
}

#root {
	height: 100%;
}
